import React from "react"
import styled from "@emotion/styled"
import { colours } from "../index"

const SpinningCircle = styled.circle`
  transform-origin: center;
  animation: 0.7s spin linear infinite;

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`

interface Props {
  width?: number
  height?: number
  color?: string
  backgroundColor?: string
  strokeWidth?: number
  title?: string
}

export const Spinner: React.FunctionComponent<Props> = ({
  width = 24,
  height = 24,
  color = "currentcolor",
  backgroundColor = colours.greyScale.grey75,
  strokeWidth = 5,
  title,
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 66 66"
      strokeWidth={strokeWidth}
      xmlns="http://www.w3.org/2000/svg"
      role="img"
      className="spinner"
    >
      <title>{title || "Loading"}</title>

      <circle
        fill="none"
        strokeLinecap="round"
        cx="33"
        cy="33"
        r="30"
        stroke={backgroundColor}
        className="spinner__background"
      ></circle>
      <SpinningCircle
        fill="none"
        strokeLinecap="round"
        cx="33"
        cy="33"
        r="30"
        stroke={color}
        strokeDashoffset="150px"
        strokeDasharray="190px"
        className="spinner__color"
      ></SpinningCircle>
    </svg>
  )
}
